import React, { Component } from 'react';
import { connect } from 'react-redux';
import { adminGetAllProperties } from '../../actions/propertyActions';
// import isEmpty from '../../validation/is-empty';
import { Link } from 'react-router-dom';
import isEmpty from '../../validation/is-empty';

// import components
import AddPropertyModal from './AddPropertyModal';
import AdminPage from './AdminPage';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

class ManageProperties extends Component {
  componentDidMount() {
    this.props.adminGetAllProperties();
  }

  getRoomTotal(property) {
    let roomTotal = 0;

    property.flats.map(obj => {
      return (roomTotal += obj.flat.rooms.length);
    });

    return roomTotal;
  }

  render() {
    const { properties } = this.props.properties;

    const columns = [
      {
        Header: 'Name',
        accessor: 'name',
        minResizeWidth: 10
      },
      {
        Header: 'Address',
        id: 'address',
        accessor: d =>
          d.address ? `${d.address.line1}, ${d.address.postcode}` : '-',
        minResizeWidth: 10
      },
      {
        Header: 'Flats',
        id: 'flats',
        className: 'text-center',
        accessor: d => d.flats.length,
        minResizeWidth: 10
      },
      {
        Header: 'Rooms',
        id: 'rooms',
        className: 'text-center',
        accessor: d => {
          let roomTotal = 0;

          d.flats.map(({ flat }) => {
            if (!isEmpty(flat.rooms)) {
              return (roomTotal += flat.rooms.length);
            } else {
              return null;
            }
          });

          return roomTotal;
        },
        minResizeWidth: 10
      },
      {
        Header: 'Featured?',
        className: 'text-center',
        id: 'featured-status',
        accessor: d => (d.featured ? 'Yes' : 'No'),
        minResizeWidth: 10
      },
      {
        Header: 'Active?',
        className: 'text-center',
        id: 'active-status',
        accessor: d => (d.active ? 'Yes' : 'No'),
        minResizeWidth: 10
      },
      {
        Header: 'Order',
        className: 'text-center',
        accessor: 'orderby',
        minResizeWidth: 5
      },
      {
        Header: 'Manage',
        className: 'text-center',
        accessor: '_id',
        Cell: d => (
          <Link
            to={`/admin-panel/property/edit/${d.value}`}
            className="badge badge-pill"
          >
            Manage
          </Link>
        )
      }
    ];

    const filterCaseInsensitive = (filter, row) => {
      const id = filter.pivotId || filter.id;
      if (row[id] !== null && typeof row[id] === 'string') {
        return row[id] !== undefined
          ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
          : true;
      } else {
        return row[id] !== undefined
          ? String(row[id]).startsWith(filter.value)
          : true;
      }
    };

    return (
      <AdminPage title="Manage Properties">
        <p>
          You can sort by column by just selecting the column headers. You can
          also sort by <strong>multiple columns</strong>, hold shift and select
          multiple headings. If you're looking for a specific entry you can
          search inside the white box under the column headings, please note
          this search is <strong>case sensitive.</strong>
        </p>

        <ReactTable
          data={properties}
          columns={columns}
          defaultPageSize={10}
          defaultFilterMethod={filterCaseInsensitive}
          filterable
        />

        <div className="footer">
          <button
            className="btn green-button d-block ml-auto"
            data-toggle="modal"
            data-target="#addPropertyModal"
          >
            Add Property
          </button>

          <AddPropertyModal />
        </div>
      </AdminPage>
    );
  }
}

const mapStateToProps = state => ({
  properties: state.properties
});

export default connect(
  mapStateToProps,
  { adminGetAllProperties }
)(ManageProperties);
