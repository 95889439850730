import React, { Component } from 'react';

// import components
import Hero from '../common/Hero';
import axios from 'axios';

class ContentPage extends Component {
  render() {
    const { images, heading, children, banimages } = this.props;

    console.log(banimages);
    return (
      <main className="content-page">

        <Hero />

        <section className="main-content">
          <div className="container">
            <div className="row">
              <div className="col-md-8 mx-auto">
                {heading ? (
                  <div className="header">
                    <h1 className="heading">{heading}</h1>
                  </div>
                ) : null}

                {children}
              </div>

              <div className="col-md-4">
                {banimages && (
                  // <div className="col-md-4">
                    <div className="images-container">
                      {banimages
                        ? banimages.map((image, index) => (                          
                            image.mediaKey && (
                              <img src={`/api/media/image/${image?.mediaKey}`} alt="Student Bedroom" key={index} width={300}/>
                              // <img src={image.mediaKey} alt={image.alt} key={index} />
                              // <img src={image.src} alt={image.alt} key={index} />
                            )
                          ))
                        : null}
                    </div>
                  // </div>
                )}

                {/* {images && (
                    <div className="images-container">
                      {images
                        ? images.map((image, index) => (
                            <img src={image.src} alt={image.alt} key={index} />
                          ))
                        : null}
                    </div>
                )} */}
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default ContentPage;
