import React, { Component } from "react";
import isEmpty from "../../validation/is-empty";

// import components
import Spinner from "../common/Spinner";

class KeyFeaturesTab extends Component {
  constructor() {
    super();

    this.state = {
      features: [],
    };
  }

  render() {
    const { property, loading } = this.props;
    let featuresContent,
      features = [];

    if (loading || isEmpty(property)) {
      featuresContent = (
        <div
          className="tab-pane fade show active"
          id="features"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <Spinner />
        </div>
      );
    } else {
      features = property.features.split(", ");

      featuresContent = (
        <div
          className="tab-pane fade show"
          id="features"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          {features.length > 0 ? (
            <div className="key-features">
              <h3 className="heading">Key features</h3>

              <div className="row no-gutters">
                {features.map((feature) => (
                  <div className="col-md-6 feature" key={feature}>
                    <div className="diamond-icon" />
                    <p>{feature}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      );
    }
    return featuresContent;
  }
}

export default KeyFeaturesTab;
