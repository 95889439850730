import React, { Component } from "react";
import placeholder from "../../img/coming-soon.png";
import { Link } from "react-router-dom";
import isEmpty from "../../validation/is-empty";

import SaveButton from "./SaveButton";

export default class PropertyCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      period: "month", // Default to 'week'
    };
  }

  handlePeriodChange = (event) => {
    event.stopPropagation(); // Prevent the event from bubbling up
    this.setState({ period: event.target.value });
  };

  getMinPrice() {
    const { flats, individualRooms } = this.props.property;
    const prices = [];
    let lowest, highest, tmp;

    if (flats.length > 0) {
      flats.map((obj) => {
        if (obj.flat.rooms.length > 0) {
          return obj.flat.rooms.map((obj) => {
            return prices.push(obj?.room?.price);
          });
        } else {
          return null;
        }
      });
    } else if (individualRooms.length > 0) {
      individualRooms.map(({ room }) => {
        return prices.push(room?.price);
      });
    }

    if (prices.length === 0) {
      return null;
    }

    if (prices.every((val, i, arr) => val === arr[0])) {
      return this.formatPrice(prices[0]);
    } else {
      lowest = Number.POSITIVE_INFINITY;
      highest = Number.NEGATIVE_INFINITY;

      for (let i = prices.length - 1; i >= 0; i--) {
        tmp = prices[i];
        if (tmp < lowest) lowest = tmp;
        if (tmp > highest) highest = tmp;
      }
      return `from ${this.formatPrice(lowest)}`;
    }
  }

  formatPrice(price) {
    const { period } = this.state;

    if (period === "month") {
      price *= 4; // Assuming 4 weeks per month
    }

    return `£${price} per `;
  }

  getFlatCapacity() {
    const { flats, individualRooms } = this.props.property;
    const capacities = [];

    if (flats.length > 0) {
      flats.map((obj) => {
        // we don't want capacities of 0 being added to the array
        if (obj.flat.rooms.length > 0) {
          return capacities.push(obj.flat.rooms.length);
        } else {
          return null;
        }
      });

      if (capacities.every((val, i, arr) => val === arr[0])) {
        return `${capacities[0]} bedroom flats`;
      } else {
        var lowest = Number.POSITIVE_INFINITY;
        var highest = Number.NEGATIVE_INFINITY;
        var tmp;

        for (var i = capacities.length - 1; i >= 0; i--) {
          tmp = capacities[i];
          if (tmp < lowest) lowest = tmp;
          if (tmp > highest) highest = tmp;
        }
        return `${lowest}-${highest} bedroom flats`;
      }
    } else if (individualRooms.length > 0) {
      return `${individualRooms.length} bedrooms`;
    } else {
      return null;
    }
  }

  getShortDescription() {
    const { description } = this.props.property;
    let newString;

    if (description) {
      newString = description
        .split(/\s+/)
        .slice(0, 15)
        .join(" ");
    }

    return newString + "...";
  }

  render() {
    const { property } = this.props;
    console.log(property);

    return (
      <div className="property-card">
        <Link to={`/property/${property.slug}`}>
          <div className="header">
            <h2 className="heading">{property.name}</h2>

            <SaveButton property={property} />
          </div>

          <div className="row no-gutters">
            <div className="col-md-8">
              <div className="left-content">
                <div className="featured-images">
                  <div
                    className="image-block"
                    style={{
                      backgroundImage: property.images[0]
                        ? `url(/api/media/image/${property.images[0].mediaKey})`
                        : `url(${placeholder})`,
                    }}
                  >
                    {/* apply bg image */}
                  </div>
                  <div
                    className="image-block"
                    style={{
                      backgroundImage: property.images[1]
                        ? `url(/api/media/image/${property.images[1].mediaKey})`
                        : `url(${placeholder})`,
                    }}
                  >
                    {/* apply bg image */}
                  </div>
                </div>

                {/* <div className="price">
                  <h3>
                    {this.getMinPrice()}
                    <select
                      value={this.state.period}
                      onChange={this.handlePeriodChange}
                      onClick={(event) => event.stopPropagation()} // Prevent click event from bubbling up
                      style={{ marginLeft: "5px", color: "#0c0a2d", border: "2px solid #e85c0c" }}
                    >
                      <option value="week">week</option>
                      <option value="month">month</option>
                    </select>
                  </h3>
                </div> */}
              </div>
            </div>

            <div className="col-md-4">
              <div className="property-info">
                <h3 className="address">
                  {property?.address?.line1
                    ? `${property?.address?.line1}`
                    : null}
                  {property?.address?.city
                    ? `, ${property.address.city}`
                    : null}
                  {property?.address?.postcode ? (
                    <span>
                      ,{" "}
                      <span className="no-break">
                        {property.address.postcode}
                      </span>
                    </span>
                  ) : null}
                </h3>

                <ul className="meta-info">
                  <li>
                    <i className="fas fa-user fa-fw" /> {this.getFlatCapacity()}
                  </li>
                  {property?.info?.field1 ? (
                  <li>
                    <i className="fas fa-home fa-fw" /> {property.info.field1}
                  </li>
                  ) : null}
                  {property?.info?.field2 ? (
                  <li>
                    <i className="fas fa-home fa-fw" /> {property.info.field2}
                  </li>
                  ) : null}
                  {property?.info?.field3 ? (
                  <li>
                    <i className="fas fa-home fa-fw" /> {property.info.field3}
                  </li>
                  ) : null}
                  {property?.info?.field4 ? (
                  <li>
                    <i className="fas fa-home fa-fw" /> {property.info.field4}
                  </li>
                  ) : null}
                </ul>

                {/* {property.description ? (
                  <div className="description">
                    <p>{this.getShortDescription()}</p>
                  </div>
                ) : null} */}
              </div>
            </div>
          </div>
        </Link>

        <div className="left-content">
          <div className="price">
            <h3>
            {property?.monthlyprice ? (
                    `from £${property.monthlyprice} per `
                ) : this.getMinPrice() }
              {/* {this.getMinPrice()} */}
              {this.state.period}
              {/* <select
                value={this.state.period}
                onChange={this.handlePeriodChange}
                onClick={(event) => event.stopPropagation()} // Prevent click event from bubbling up
                style={{
                  marginLeft: "5px",
                  color: "#0c0a2d",
                  border: "2px solid #e85c0c",
                }}
              >
                <option value="week">week</option>
                <option value="month">month</option>
              </select> */}
            </h3>
          </div>
        </div>
      </div>
    );
  }
}
