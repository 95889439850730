import React, { Component } from 'react';
import isEmpty from '../../validation/is-empty';

// import components
import Spinner from '../common/Spinner';

class DescriptionTab extends Component {
  constructor() {
    super();

    this.state = {
      features: []
    };
  }

  render() {
    const { property, loading } = this.props;
    let descriptionContent;

    if (loading || isEmpty(property)) {
      descriptionContent = (
        <div
          className="tab-pane fade show active"
          id="description"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <Spinner />
        </div>
      );
    } else {
      descriptionContent = (
        <div
          className="tab-pane fade show active"
          id="description"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <div className="description-wrapper" id="full-description">
            <h3 className="heading">Full description</h3>

            <p>{property.description}</p>
          </div>
        </div>
      );
    }
    return descriptionContent;
  }
}

export default DescriptionTab;
