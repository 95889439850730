import { combineReducers } from 'redux';
import authReducer from './authReducer';
import propertyReducer from './propertyReducer';
import errorReducer from './errorReducer';
import userReducer from './userReducer';
import enquiryReducer from './enquiryReducer';
import resourceReducer from './resourceReducer';
import tenancyReducer from './tenancyReducer';
import paymentReducer from './paymentReducer';
import settingReducer from './settingReducer';

// combine all reducers
export default combineReducers({
  auth: authReducer,
  properties: propertyReducer,
  errors: errorReducer,
  users: userReducer,
  enquiries: enquiryReducer,
  resources: resourceReducer,
  tenancy: tenancyReducer,
  payments: paymentReducer,
  settings: settingReducer
});
