import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getProperty, deleteProperty } from '../../actions/propertyActions';
import isEmpty from '../../validation/is-empty';

// import components
import EditPropertyModal from './EditPropertyModal';
import FlatDetails from './FlatDetails';
import ImageDetails from './ImageDetails';
import Spinner from '../common/Spinner';
import AdminNavigation from '../admin/AdminNavigation';

class EditProperty extends Component {
  constructor() {
    super();

    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount() {
    this.props.getProperty(this.props.match.params.propertyId);
  }

  onDelete(e) {
    e.preventDefault();

    this.props.deleteProperty(this.props.match.params.propertyId);
  }

  render() {
    const { property, singleLoading } = this.props.properties;
    const { flats } = this.props.properties.property;
    let propertyDetails;

    if (isEmpty(property) || singleLoading) {
      propertyDetails = <Spinner />;
    } else {
      propertyDetails = (
        <div className="content">
          <div className="admin-section">
            <div className="row">
              <div className="col-md-4">
                <div className="section-info">
                  <h3 className="heading">Property Info</h3>
                  <p>The properties information, you can edit it below</p>
                </div>
              </div>

              <div className="col-md-8">
                <div className="section-content">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="meta-section">
                        <h4>Name</h4>
                        <p>{property.name}</p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="meta-section">
                        <h4>Address</h4>
                        <p>
                          {!isEmpty(property.address)
                            ? Object.keys(property.address).map(
                                (key, index) => {
                                  if (
                                    !isEmpty(property.address[key]) &&
                                    index === 0
                                  ) {
                                    return `${property.address[key]}`;
                                  } else {
                                    return `, ${property.address[key]}`;
                                  }
                                }
                              )
                            : null}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="meta-section">
                        <h4>Page Slug</h4>
                        <p>{property.slug}</p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="meta-section border-0">
                        <h4>Featured?</h4>
                        <p>{property.featured ? 'Yes' : 'No'}</p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="meta-section border-0">
                        <h4>Active?</h4>
                        <p>{property.active ? 'Yes' : 'No'}</p>
                      </div>
                    </div>

                    {!isEmpty(property.monthlyprice) ? (
                    <div className="col-md-6">
                      <div className="meta-section border-0">
                        <h4>Monthly Price</h4>
                        <p>{property.monthlyprice}</p>
                      </div>
                    </div>
                    ) : null }
                    
                    {!isEmpty(property.orderby) ? (
                    <div className="col-md-6">
                      <div className="meta-section border-0">
                        <h4>Property Order</h4>
                        <p>{property.orderby}</p>
                      </div>
                    </div>
                    ) : null }
                    
                    <div className="col-md-12">
                      <EditPropertyModal property={property} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ImageDetails property={property} />

          <FlatDetails flats={flats} property={property} />

          <div className="admin-section remove-footer">
            <h2 className="heading">Delete Property</h2>
            <p>
              <strong className="text-uppercase">Warning:</strong> Properties
              cannot be recovered once they are deleted. When deleting a
              property, the attached flats and rooms are also permanently
              deleted. Please ensure that you take caution.
            </p>

            <button className="btn red-button" onClick={this.onDelete}>
              Delete Property
            </button>
          </div>
        </div>
      );
    }

    return (
      <section className="admin-panel">
        <div className="container">
          <AdminNavigation />
          <div className="panel-content">
            <h1 className="heading">
              Manage Property{' '}
              {property._id ? (
                <small>
                  <code>ID: {property._id}</code>{' '}
                </small>
              ) : null}
            </h1>

            {propertyDetails}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  properties: state.properties
});

export default connect(
  mapStateToProps,
  { getProperty, deleteProperty }
)(EditProperty);
