import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

// import components
import ContentPage from './ContentPage';
import glosUni from '../../img/glos-uni.jpg';
import studentDesk from '../../img/student-desk.jpg';

class AboutGlos extends Component {
  constructor() {
    super();

    this.state = {
      title: '',
      content: '',
      banimages: '',
    };
  }

  componentDidMount() {
    // this.setState({ name: 'Gloucester' });

    axios
    .get('/api/settings/66d5b3214bed45988c184a07')
    .then(res => {
      this.setState({
        title: res.data.name,
        content: res.data.description,
        banimages: res.data.images,
      });
    });
  }

  render() {
    const { title, content, banimages } = this.state;

    const images = [
      {
        src: glosUni,
        alt: 'Gloucester University Grounds'
      },
      {
        src: studentDesk,
        alt: 'Student doing work at her desk'
      }
    ];

    return (
      <ContentPage heading={title} images={images} banimages={banimages}>
        <Helmet>
          <title>
            {/* About Gloucestershire */}
            {title}
          </title>
        </Helmet>

        <div className="text-content">
          <div dangerouslySetInnerHTML={{ __html: content }} />

          {/* <p>
            Gloucestershire a great place to come and study. The county is home
            to the University of Gloucestershire, Hartpury University and
            College and the Royal Agricultural University in Cirencester.
          </p>

          <p>
            Being centrally located with good road and rail links,
            Gloucestershire has vibrant towns and cities with excellent night
            life, leisure opportunities and events, yet you are almost always
            just minutes from stunning countryside and the peace and quiet. For
            those students looking to work in the area there are also a lot of
            job opportunities in leisure, retail and other sectors.
          </p>

          <p>
            Here we take a quick look at each of the county’s educational
            establishments.
          </p>

          <hr />

          <h3 className="subheading">University of Gloucestershire</h3>

          <p>
            The University of Gloucestershire’s ethos is to treat every one of
            their students as a person, not a number. Study here and you’ll be
            joining a close-knit, supportive community of staff and students who
            share your passions, academic interests and goals. The University
            also prides itself on helping you develop the confidence, skills and
            experiences that you need to achieve your aspirations for the
            future.
          </p>

          <p>The University of Gloucestershire has four campuses:</p>

          <p>
            Park Campus, Cheltenham is home to a range of computing, technology,
            design and media courses.
          </p>

          <p>
            Francis Close Hall, is the university campus nearest to Cheltenham’s
            town centre. Francis Close Hall is home to subjects such as art,
            education, health and social care, liberal arts and natural and
            social sciences.
          </p>

          <p>
            Hardwick, Cheltenham is The Centre for Art and Photography and is
            just a short walk from Francis Close Hall.
          </p>

          <p>
            Oxstalls campus is located close to the heart of Gloucester.
            Oxstalls is where sports and exercise, nursing, performing arts,
            dance, drama, accounting, law, business and management subjects are
            studied.
          </p>

          <p>
            The University of Gloucestershire is continually looking to invest
            in its facilities and its new Business School, uniquely co-located
            with the Gloucestershire Growth Hub, with all the business
            connections that this brings was recently opened by the Chancellor
            of the Exchequer.
          </p>

          <hr />

          <h3 className="subheading">
            Hartpury Unveristy and Hartpury College
          </h3>

          <p>
            Hartpury University and Hartpury College are co-located on a 360
            acre site close to Gloucester.
          </p>

          <p>
            Hartpury University has a global reputation in agriculture, animal,
            equine, sport and veterinary nursing. Many of their lecturers are
            producing cutting-edge research that’s leading the way in industry
            best practice. The university prides itself on its excellent
            connections with some of the world’s biggest names and brands they
            are also rated among the best in the UK for teaching quality,
            holding Teaching Excellence Framework Gold.
          </p>

          <p>
            Hartpury College was rated by Ofsted as Outstanding (2018) and also
            rated by the Department of Education as the highest achieving
            land-based college in the UK (2016/17).
          </p>

          <p>
            Students can choose from A-levels or vocational diplomas. They offer
            a range of qualifications for those aged 16 upwards, designed to
            suit different learning styles and abilities.
          </p>

          <hr />

          <h3 className="subheading">Gloucestershire College</h3>

          <p>Gloucestershire College’s tagline is “Learning that works”</p> */}
        </div>
      </ContentPage>
    );
  }
}

export default AboutGlos;
