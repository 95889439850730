import React, { Component } from 'react';

// import icons
import ParkIcon from '../../img/icons-purple/education.png';
import NightlifeIcon from '../../img/icons-purple/nightlife.png';
import ShoppingIcon from '../../img/icons-purple/shopping.png';
import CityCentreIcon from '../../img/icons-purple/city-centre.png';
import BusIcon from '../../img/icons-purple/bus.png';
import TrainIcon from '../../img/icons-purple/train.png';
import SupermarketIcon from '../../img/icons-purple/groceries.png';
import StadiumIcon from '../../img/icons-purple/stadium.png';
import RaceCourseIcon from '../../img/icons-purple/horse-head.png';

export default class AreaInfo extends Component {
  render() {
    const { area } = this.props;
    console.log(area);

    return (
      <div
        className="in-the-area tab-pane fade show"
        id="inTheArea"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <h3 className="heading">In the area</h3>

        <div className="stats">
          <div className="row no-gutters">
            {area.nightlife && (
              <div className="col-md-6">
                <div className="stat">
                  <div className="img-wrapper">
                    <img src={NightlifeIcon} alt="Nightlife Icon" />
                  </div>

                  <div className="text-content">
                    <p>{area.nightlife}</p>
                  </div>
                </div>
              </div>
            )}

            {area.shopping && (
              <div className="col-md-6">
                <div className="stat">
                  <div className="img-wrapper">
                    <img src={ShoppingIcon} alt="Shopping Icon" />
                  </div>

                  <div className="text-content">
                    <p>{area.shopping}</p>
                  </div>
                </div>
              </div>
            )}

            {area.trainStation && (
              <div className="col-md-6">
                <div className="stat">
                  <div className="img-wrapper">
                    <img src={TrainIcon} alt="Train Station Icon" />
                  </div>

                  <div className="text-content">
                    <p>{area.trainStation}</p>
                  </div>
                </div>
              </div>
            )}

            {area.busStation && (
              <div className="col-md-6">
                <div className="stat">
                  <div className="img-wrapper">
                    <img src={BusIcon} alt="Bus Icon" />
                  </div>

                  <div className="text-content">
                    <p>{area.busStation}</p>
                  </div>
                </div>
              </div>
            )}

            {area.park && (
              <div className="col-md-6">
                <div className="stat">
                  <div className="img-wrapper">
                    <img src={ParkIcon} alt="Park Icon" />
                  </div>

                  <div className="text-content">
                    <p>{area.park}</p>
                  </div>
                </div>
              </div>
            )}

            {area.supermarket && (
              <div className="col-md-6">
                <div className="stat">
                  <div className="img-wrapper">
                    <img src={SupermarketIcon} alt="Supermarket Icon" />
                  </div>

                  <div className="text-content">
                    <p>{area.supermarket}</p>
                  </div>
                </div>
              </div>
            )}

            {area.cityCentre && (
              <div className="col-md-6">
                <div className="stat">
                  <div className="img-wrapper">
                    <img src={CityCentreIcon} alt="City Centre Icon" />
                  </div>

                  <div className="text-content">
                    <p>{area.cityCentre}</p>
                  </div>
                </div>
              </div>
            )}

            {area.stadium && (
              <div className="col-md-6">
                <div className="stat">
                  <div className="img-wrapper">
                    <img src={StadiumIcon} alt="Stadium Icon" />
                  </div>

                  <div className="text-content">
                    <p>{area.stadium}</p>
                  </div>
                </div>
              </div>
            )}

            {area.raceCourse && (
              <div className="col-md-6">
                <div className="stat">
                  <div className="img-wrapper">
                    <img src={RaceCourseIcon} alt="Race Course Icon" />
                  </div>

                  <div className="text-content">
                    <p>{area.raceCourse}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
