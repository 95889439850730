import axios from 'axios';

// import types
import {
  GET_ALL_SETTINGS,
  SETTINGS_LOADING,
  GET_SETTING,
  UPDATE_SETTING,
  UPLOAD_IMAGE,
  GET_ERRORS,
  CLEAR_ERRORS
} from './types';

// get all settings
export const getAllSettings = () => dispatch => {
  setSettingsLoading();

  axios
    .get(`/api/settings`)
    .then(res => {
      dispatch({
        type: GET_ALL_SETTINGS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// get single setting
export const getSetting = (settingId) => (dispatch) => {
  dispatch(setSettingsLoading());
  dispatch({ type: CLEAR_ERRORS });

  axios
    .get(`/api/settings/${settingId}`)
    .then((res) => {
      // console.log(res.data);
      dispatch({
        type: GET_SETTING,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// update setting
export const updateSetting = (settingId, settingData) => (dispatch) => {
  console.log(' updateSetting '.settingData);
  axios
    .post(`/api/settings/${settingId}`, settingData)
    .then((res) => {
      dispatch({
        type: UPDATE_SETTING,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// upload image
export const uploadImage = (settingId, image) => (dispatch) => {
  axios
    .post(`/api/media/setting-image/${settingId}`, image)
    .then((res) => {
      dispatch({
        type: UPLOAD_IMAGE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};


// set settings loading
export const setSettingsLoading = () => {
  return {
    type: SETTINGS_LOADING
  };
};