import {
  GET_ALL_SETTINGS,
  SETTINGS_LOADING,
  GET_SETTING,
  UPDATE_SETTING,
  UPLOAD_IMAGE,
} from "../actions/types";

const initialState = {
  settings: [],
  setting: null,
  loading: false,
  singleLoading: false,
  isUpdated: false
};

export default function (state = initialState, action) {
  // console.log(action.payload);
  switch (action.type) {
    case GET_ALL_SETTINGS:
      return {
        ...state,
        settings: action.payload,
        loading: false,
        isUpdated: false
      };
    case GET_SETTING:
      return {
        ...state,
        setting: action.payload,
        singleLoading: false,
        isUpdated: false
      };
    case UPDATE_SETTING:
      return {
        ...state,
        setting: action.payload,
        isUpdated: true
      };
    case UPLOAD_IMAGE:
      return {
        ...state,
        setting: action.payload,
        isUpdated: false
      };
    case SETTINGS_LOADING:
      return {
        ...state,
        loading: true,
        isUpdated: false
      };
    default:
      return state;
  }
}