import React, { Component } from "react";
import { Helmet } from "react-helmet";

// import components
import PropertyFilter from "./PropertyFilter";
import FeaturedProperties from "./FeaturedProperties";
import BlogPosts from "./BlogPosts";
import Hero from "../common/Hero";
import axios from 'axios';
// import VirusNotice from "../common/VirusNotice";

class Home extends Component {
  /*
  constructor() {
    super();

    this.state = {
      name: '',
      image: '',
      link: '',
    };
  }
  
  componentDidMount() {
    this.setState({ name: 'test' });

    axios
    .get('/api/settings/66d0b420481472951fdb7874')
    .then(res => {
      this.setState({
        name: res.data.name,
        image: res.data.image,
        link: res.data.link
      });
    });
  }
  */

  render() {
    // const { name, image, link } = this.state;

    return (
      <main className="homepage">
        <Helmet titleTemplate="%s">
          <title> 
            Student Digs Gloucestershire | We take the fuss out of student
            accommodation
          </title>
        </Helmet>

        {/* <Hero img={image} link={link} /> */}
        
        <Hero />

        {/* <VirusNotice /> */}

        <PropertyFilter />

        <FeaturedProperties />

        <BlogPosts />
      </main>
    );
  }
}

export default Home;
