import React, { Component } from 'react';
import isEmpty from '../../validation/is-empty';

// import components
import Spinner from '../common/Spinner';
import SaveButton from '../properties/SaveButton';

class Header extends Component {
  render() {
    const { property } = this.props;
    let headerContent;

    if (isEmpty(property)) {
      headerContent = <Spinner />;
    } else {
      headerContent = (
        <div className="property-header">
          <div className="main-meta">
            <h1 className="heading">{property.name}</h1>

            <SaveButton property={property} isPropertyDetails={true} />
          </div>
          <h3 className="address">
            {!isEmpty(property.address)
              ? Object.keys(property.address).map((key, index) => {
                  if (!isEmpty(property.address[key]) && index === 0) {
                    return `${property.address[key]}`;
                  } else {
                    return `, ${property.address[key]}`;
                  }
                })
              : null}
          </h3>
        </div>
      );
    }

    return headerContent;
  }
}

export default Header;
