import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// import components
import Hero from '../common/Hero';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';
import MapFooter from './MapFooter';
import axios from 'axios';

class Contact extends Component {
  render() {
    return (
      <div className="contact-page">
        <Helmet>
          <title>Contact Us</title>
        </Helmet>

        <Hero />

        <div className="main-content">
          <div className="container">
            <div className="row">
              <div className="col-md-7 order-2 order-md-1">
                <ContactForm />
              </div>

              <div className="col-md-5 order-1 order-md-2">
                <ContactInfo />
              </div>
            </div>
          </div>
        </div>

        <MapFooter />
      </div>
    );
  }
}

export default Contact;
