import React, { Component } from "react";
import { Link } from "react-router-dom";
import isEmpty from "../../validation/is-empty";
import placeholder from "../../img/coming-soon.png";

class FeaturedProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      period: "month", // Default to 'week'
    };
  }

  getMinPrice() {
    const { flats, individualRooms } = this.props.property;
    const prices = [];

    if (flats.length > 0) {
      // map through each flat
      flats.map((obj) => {
        // map through each room in flat and add price to array
        if (obj.flat.rooms.length > 0) {
          return obj.flat.rooms.map((obj) => {
            return prices.push(obj?.room?.price);
          });
        } else {
          return null;
        }
      });

      if (prices.every((val, i, arr) => val === arr[0])) {
        // return `£${prices[0]} / per week`;
        return this.formatPrice(prices[0]);
      } else {
        var lowest = Number.POSITIVE_INFINITY;
        var highest = Number.NEGATIVE_INFINITY;
        var tmp;

        for (var i = prices.length - 1; i >= 0; i--) {
          tmp = prices[i];
          if (!isEmpty(tmp)) {
            if (tmp < lowest) lowest = tmp;
            if (tmp > highest) highest = tmp;
          }
        }

        // return `from £${lowest} / per week`;
        return `from ${this.formatPrice(lowest)}`;
      }
    } else if (individualRooms.length > 0) {
      individualRooms.map(({ room }) => {
        return prices?.push(room?.price);
      });

      if (prices.every((val, i, arr) => val === arr[0])) {
        // return `£${prices[0]} per week`;
        return this.formatPrice(prices[0]);
      } else {
        lowest = Number.POSITIVE_INFINITY;
        highest = Number.NEGATIVE_INFINITY;

        for (let i = prices.length - 1; i >= 0; i--) {
          tmp = prices[i];
          if (tmp < lowest) lowest = tmp;
          if (tmp > highest) highest = tmp;
        }
        // return `from £${lowest} per week`;
        return `from ${this.formatPrice(lowest)}`;
      }
    } else if (individualRooms.length > 0) {
      return `${individualRooms.length} bedrooms`;
    } else {
      return null;
    }
  }

  formatPrice(price) {
    const { period } = this.state;

    if (period === "month") {
      price *= 4; // Assuming 4 weeks per month
    }

    return `£${price} per `;
  }

  getFlatCapacity() {
    const { flats, individualRooms } = this.props.property;
    const capacities = [];

    if (flats.length > 0) {
      flats.map((obj) => {
        // we don't want capacities of 0 being added to the array
        if (obj.flat.rooms.length > 0) {
          return capacities.push(obj.flat.rooms.length);
        } else {
          return null;
        }
      });

      if (capacities.every((val, i, arr) => val === arr[0])) {
        return `${capacities[0]} bed flats`;
      } else {
        var lowest = Number.POSITIVE_INFINITY;
        var highest = Number.NEGATIVE_INFINITY;
        var tmp;
        for (var i = capacities.length - 1; i >= 0; i--) {
          tmp = capacities[i];
          if (tmp < lowest) lowest = tmp;
          if (tmp > highest) highest = tmp;
        }
        return `${lowest}-${highest} bed flats`;
      }
    } else if (individualRooms.length > 0) {
      return `${individualRooms.length} bedrooms`;
    } else {
      return null;
    }
  }

  render() {
    const { property } = this.props;
    let backgroundImage;

    if (isEmpty(property.images)) {
      backgroundImage = placeholder;
    } else {
      backgroundImage = `/api/media/image/${property.images[0].mediaKey}`;
    }

    return (
      <div className="col-md-4 col-sm-6">
        <Link to={`/property/${property.slug}`} className="property-link">
          <div className="property">
            <div
              className="featured-image"
              style={{ backgroundImage: `url(${backgroundImage})` }}
            >
              {/* Apply BG Image */}
            </div>

            <div className="property-info">
              <div className="meta-header">
                <h3 className="name">{property.name}</h3>
                <hr />
                <h4 className="price">
                  {property?.monthlyprice ? (
                      `from £${property.monthlyprice} per `
                  ) : this.getMinPrice() }
                  {/* {this.getMinPrice()} */}
                  {this.state.period}
                  </h4>
              </div>

              <ul className="meta-data">
                <li>
                  <i className="fas fa-user" /> {this.getFlatCapacity()}
                </li>
                <li>
                  <i className="fas fa-map-marker-alt" />{" "}
                  {property.address.city}
                </li>
              </ul>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default FeaturedProperty;
