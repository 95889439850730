import React, { Component } from "react";
import { connect } from "react-redux";
import {
  uploadImage,
  deleteImage,
  updateProperty,
} from "../../actions/propertyActions";

// const tempImages = [
//   {
//     _id: "5f771f90b15ab6001747da4f",
//     mediaKey: "58fd66a0-04ac-11eb-90b9-29d80997bf8e",
//     sort_order_id: 1,
//   },
//   {
//     _id: "5f771f9bb15ab6001747da50",
//     mediaKey: "5f7dad50-04ac-11eb-90b9-29d80997bf8e",
//     sort_order_id: 2,
//   },
//   {
//     _id: "5f772017b15ab6001747da51",
//     mediaKey: "a9a745d0-04ac-11eb-90b9-29d80997bf8e",
//     sort_order_id: 3,
//   },
// ];

class ImageDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: props.property.images || [],
      // images: tempImages || [],
      draggedImageIndex: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.property.images !== this.props.property.images) {
      this.setState({ images: this.props.property.images });
    }
  }

  handleSelectedFile = (event) => {
    const selectedFile = event.target.files[0];
    const { property } = this.props;

    const img = new FormData();
    img.append("image", selectedFile, selectedFile.name);

    // Set the sort_order_id as the next number in sequence
    const sort_order_id = this.state.images.length
      ? Math.max(...this.state.images.map((img) => img.sort_order_id)) + 1
      : 1;
    img.append("sort_order_id", sort_order_id);

    this.props.uploadImage(property._id, img);
  };

  deleteSelectedImage = (mediaKey) => {
    const { property } = this.props;
    const updatedImages = this.state.images.filter(
      (img) => img.mediaKey !== mediaKey
    );

    // Reassign sort_order_id for remaining images
    const reorderedImages = updatedImages.map((img, index) => ({
      ...img,
      sort_order_id: index + 1,
    }));

    const propertyData = {
      // ...property,
      // images: reorderedImages,
      ...property.area,
      name: property?.name,
      slug: property?.slug,
      addressLine1: property?.address?.line1,
      addressCity: property?.address?.city,
      addressCounty: property?.address?.county,
      addressPostcode: property?.address?.postcode,
      images: reorderedImages,
    };

    this.props.updateProperty(property._id, propertyData);
    this.setState({ images: reorderedImages });
  };

  onDragStart = (e, index) => {
    this.setState({ draggedImageIndex: index });
  };

  onDragOver = (e, index) => {
    e.preventDefault();
  };

  onDrop = (e, dropIndex) => {
    const { images, draggedImageIndex } = this.state;
    const { property } = this.props;

    if (draggedImageIndex === dropIndex) {
      return; // No need to update if dropped in the same position
    }

    const updatedImages = images.slice();
    const draggedImage = updatedImages[draggedImageIndex];
    updatedImages.splice(draggedImageIndex, 1);
    updatedImages.splice(dropIndex, 0, draggedImage);

    // Update sort_order_id for all images based on their new position
    const reorderedImages = updatedImages.map((img, index) => ({
      ...img,
      sort_order_id: index + 1,
    }));

    const propertyData = {
      ...property.area,
      name: property?.name,
      slug: property?.slug,
      addressLine1: property?.address?.line1,
      addressCity: property?.address?.city,
      addressCounty: property?.address?.county,
      addressPostcode: property?.address?.postcode,
      images: reorderedImages,
    };
    this.props.updateProperty(property._id, propertyData);
    this.setState({ draggedImageIndex: null, images: reorderedImages });
  };

  render() {
    const { images } = this.state;
    return (
      <div className="admin-section image-details">
        <div className="row">
          <div className="col-md-4">
            <div className="section-info">
              <h3 className="heading">Property Images</h3>
              <p>
                Here are the property images, the first two images will be the
                images featured in the property lists.{" "}
                <i>Recommended photo size is 1200x800px.</i>
              </p>

              <div className="new-media">
                <h3 className="subheading">Upload new images</h3>
                <input
                  type="file"
                  id="avatar"
                  name="avatar"
                  accept="image/png, image/jpeg"
                  onChange={this.handleSelectedFile}
                />
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="section-content">
              <div className="row">
                {images
                  .slice()
                  .sort((a, b) => a.sort_order_id - b.sort_order_id)
                  .map((image, i) => (
                    <div
                      className="col-md-4 col-6"
                      key={image.mediaKey}
                      draggable
                      onDragStart={(e) => this.onDragStart(e, i)}
                      onDragOver={(e) => this.onDragOver(e, i)}
                      onDrop={(e) => this.onDrop(e, i)}
                    >
                      <div className="img-wrapper">
                        <button
                          className="delete-icon"
                          onClick={() =>
                            this.deleteSelectedImage(image.mediaKey)
                          }
                        >
                          <span className="fa-stack">
                            <i className="fas fa-circle fa-stack-2x" />
                            <i className="fas fa-times fa-stack-1x fa-inverse" />
                          </span>
                        </button>
                        <img
                          src={`/api/media/image/${image.mediaKey}`}
                          alt=""
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { uploadImage, deleteImage, updateProperty })(
  ImageDetails
);
