import React, { useEffect, useState } from "react";
import bedroomImage from "../../img/bedroom.png";
import Axios from "axios";

export default function Hero() {
  const [image, setImage] = useState(null);
  const [link, setLink] = useState(null)
  
  useEffect(() => { 
    getBannerDetails();
  }, []);

  function getBannerDetails() {
    return Axios
    .get('/api/settings/66d0b420481472951fdb7874')
    .then(res => {
      // console.log(res.data);
      setImage(res.data.image);
      setLink(res.data.link);
    });
  }

  // console.log(image, link);
  return (
    <section className="hero">
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">

            {link ? (
              <a href={link}>
                {image ? (
                  <img src={`/api/media/image/${image}`} alt="Student Bedroom" />
                ) : ('')}
              </a>
            ) : (
              image ? (
                <img src={`/api/media/image/${image}`} alt="Student Bedroom" />
              ) : ('')
            )}

            {/* <a href={link}>
            <img src={`/api/media/image/${img}`} alt="Student Bedroom" />
            <img src={bedroomImage} alt="Student Bedroom" />
            </a> */}
          </div>
        </div>
      </div>
    </section>
  );
}
