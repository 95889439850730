import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

// import components
import ContentPage from './ContentPage';
import glosPark from '../../img/glos-park.jpg';
import glosUni from '../../img/glos-uni.jpg';
import studentDesk from '../../img/student-desk.jpg';

class AboutGloucester extends Component {
  constructor() {
    super();

    this.state = {
      title: '',
      content: '',
    };
  }

  componentDidMount() {
    // this.setState({ name: 'Gloucester' });

    axios
    .get('/api/settings/66d8bda74787cb2435ccf8c3')
    .then(res => {
      this.setState({
        title: res.data.name,
        content: res.data.description,
        banimages: res.data.images,
      });
    });
  }

  render() {
    const { title, content, banimages } = this.state;
    
    const images = [
      {
        src: glosPark,
        alt: 'View of Gloucester Park'
      },
      {
        src: glosUni,
        alt: 'Gloucester University Grounds'
      },
      {
        src: studentDesk,
        alt: 'Student doing work at her desk'
      }
    ];

    return (
      <ContentPage heading={title} images={images} banimages={banimages}>
        <Helmet>
          <title>
            {/* About Gloucester */}
            {title}
            </title>
        </Helmet>

        <div className="text-content">          
          <div dangerouslySetInnerHTML={{ __html: content }} />

          {/* <p>
            Now is an exciting time to choose Gloucester as your place to stay
            when studying at College or University. The City is well and truly
            on the up, with investment pouring into the City and some landmark
            developments taking place. As well as some amazing historic
            landmarks, such as Gloucester Cathedral, Llanthony Priory and
            Gloucester Docks, the town has a growing reputation as a regional
            leisure and social centre. Events such as the Tall Ships Festival
            and being a host city for the Rugby World Cup are putting the City
            on the map.
          </p>
          <p>
            Being part of the Cotswolds, Gloucester is known for beautiful
            surrounding countryside. However, Gloucester has plenty of greenery
            in the City, most notably Gloucester Park which at different points
            in the year also acts as flexible venue for one of the many outdoor
            events that take place in the town. The Docks area is enjoying rapid
            growth with the addition and expansion of Gloucester Quays for
            shopping, restaurants and night life. The Quays also has its own
            packed programme of events and there are plenty of part time jobs
            for those students who are looking to work whilst they study. The
            next stage in the re-development of Gloucester will see the Kings
            Quarter area, where Kings Square currently is being redeveloped.
          </p>
          <hr />
          <h3 className="subheading">
            Making Gloucester your home when studying in Gloucestershire
          </h3>
          <p>
            A vibrant and fashionable place to live, Gloucester has all the
            attractions of a metropolitan city with easy access to some of the
            most stunning countryside in the country and excellent transport
            links.
          </p>
          <p>
            The town hosts lots of free events throughout the year, with the
            Tall Ships being the jewel in the crown. There is also a major Food
            Festival, annual carnival and a Christmas market.
          </p>
          <hr />
          <h3 className="subheading">Live entertainment, Culture & Heritage</h3>
          <p>
            You will find there is no shortage of live entertainment in
            Gloucester. Gloucester Guildhall runs a year round varied programme,
            including live bands and comedy nights. Gloucester Rugby host big
            name stars for summer concerts annually and there is the up and
            coming Barn on the Farm Festival held annually just outside the
            City.
          </p>
          <p>
            For those looking for culture there are annual Classical Music
            Festivals and an Arts Festival. Heritage wise aside from the obvious
            of Gloucester Cathedral and Gloucester Docks, there are some
            wonderful historic buildings and streets, roman remains and a City
            Museum.
          </p>
          <hr />
          <h3 className="subheading">Leisure and Nightlife</h3>
          <p>
            In Gloucester there is a myriad of bars and restaurants, with new
            ones seemingly being opened every week particularly in the Docks and
            Quays area. From trendy independent bars to more traditional pubs
            and cuisine from all corners of the world, you will be spoilt for
            choice.
          </p>
          <p>
            The Quays is a buzzing district where old meets new and is home to
            national branded eateries, a multi-screen cinema and bars offering
            live gigs and entertainment throughout the week.
          </p>

          <hr />

          <h3 className="subheading">Sport and Active Lifestyles</h3>

          <p>
            Whether you are looking to participate in sport yourself or watch
            some leading sport, you will be spoilt for choice in Gloucester.
          </p>

          <p>
            The jewel in Gloucester’s crown is undoubtedly Gloucester Rugby who
            play in the Premiership at the world renowned Kingsholm Stadium. The
            City though is also soon going to see Gloucester City Football Club
            return to Gloucester in a newly developed ground. Cheltenham home to
            Cheltenham Racecourse and Cheltenham Town Football Club is just 30
            minutes away and easily accessible by public transport.
          </p>

          <p>
            For those looking to participate in sport, Gloucester has a rich
            variety of options. There is a buoyant local club scene for cricket,
            football, hockey, netball and rugby to name just a few. The City has
            its own Leisure Centre, with a swimming pool, large indoor sports
            area and gym. The City also has the Riverside Sports and Leisure
            Club. Runners are well catered for with local clubs, weekly park
            runs and two annual events the Race for Life and the Gloucester half
            marathon. For golfers there are golf courses at Matson and
            Churchdown and there is also a dry ski slope at Matson.
          </p>

          <p>
            Gloucester Rowing Club has also invested in a multi million pound
            facility called The Boathouse on Gloucester Sharpness Canal.
          </p> */}
        </div>
      </ContentPage>
    );
  }
}

export default AboutGloucester;
