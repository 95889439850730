import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import setAuthToken from './utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import { setCurrentUser, logoutUser } from './actions/authActions';
// import './css/main.css';
import './sass/main.scss';

// import provider and store
import { Provider } from 'react-redux';
import store from './store';

// import routes
// import PrivateRoute from './components/common/PrivateRoute';
import AppContainer from './components/common/AppContainer';
import AdminRoute from './components/common/AdminRoute';
import ScrollToTop from './components/common/ScrollToTop';
import PrivateRoute from './components/common/PrivateRoute';

// import components
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Home from './components/home/Home';
import Login from './components/auth/Login';
import Property from './components/property/Property';
import Properties from './components/properties/Properties';
import AboutUs from './components/content/AboutUs';
import AboutGlos from './components/content/AboutGlos';
import Contact from './components/contact/Contact';
import SavedProperties from './components/saved-properties/SavedProperties';
import ForgottenPassword from './components/auth/ForgottenPassword';
import ResetPassword from './components/auth/ResetPassword';
import NotFound from './components/content/NotFound';
import PrivacyPolicy from './components/content/PrivacyPolicy';
import CookiePolicy from './components/content/CookiePolicy';

// user panel components
import UserHome from './components/user-panel/UserHome';
import Resources from './components/user-panel/Resources';
import TenantContact from './components/user-panel/Contact';
import Payments from './components/user-panel/Payments';
import Pay from './components/user-panel/Pay';
import Secure from './components/user-panel/Secure';

// admin components
import AdminHome from './components/admin/AdminHome';
import EditProperty from './components/edit-property/EditProperty';
import ManageProperties from './components/admin/ManageProperties';
import ManageUsers from './components/admin-users/ManageUsers';
import ManageUser from './components/admin-users/ManageUser';
import ManageEnquiries from './components/admin-enquiries/ManageEnquiries';
import ViewEnquiry from './components/admin-enquiries/ViewEnquiry';
import TenancyPanel from './components/tenants/TenancyPanel';
import ManagePayments from './components/admin-payments/ManagePayments';
import ManagePayment from './components/admin-payments/ManagePayment';
import ManageTenantEnquiries from './components/admin-tenant-enquiries/ManageTenantEnquiries';
import ViewTenantEnquiry from './components/admin-tenant-enquiries/ViewTenantEnquiry';
import AboutGloucester from './components/content/AboutGloucester';
import PaymentsSummary from './components/admin-payments-summary';
import ManageSettings from './components/admin/ManageSettings';
import EditSetting from './components/settings/EditSetting';

// Check for token
if (localStorage.jwtToken) {
	// Set auth token header auth
	setAuthToken(localStorage.jwtToken);
	// Decode token and get user info and exp
	const decoded = jwt_decode(localStorage.jwtToken);
	// Set user and isAuthenticated
	store.dispatch(setCurrentUser(decoded));

	// Check for expired token
	const currentTime = Date.now() / 1000;
	if (decoded.exp < currentTime) {
		// Logout user
		store.dispatch(logoutUser());
		// Clear current Profile
		// store.dispatch(clearCurrentProfile());
		// Redirect to login
		window.location.href = '/';
	}
}

class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<Helmet titleTemplate='%s | Student Digs Gloucestershire'>
					<title>We take the fuss out of student accommodation</title>
					<meta
						name='description'
						content='We take the fuss out of student accommodation'
					/>
				</Helmet>

				<Router>
					<AppContainer>
						<ScrollToTop>
							<div className='App'>
								<Header />
								<Switch>
									<Route exact path='/' component={Home} />
									<Route path='/login' component={Login} />
									<Route
										path='/property/:slug'
										component={Property}
									/>
									<Route
										path='/accommodation'
										component={Properties}
									/>
									<Route
										path='/about-us'
										component={AboutUs}
									/>
									<Route
										path='/about-glos'
										component={AboutGlos}
									/>
									<Route
										path='/about-gloucester'
										component={AboutGloucester}
									/>
									<Route
										path='/privacy-policy'
										component={PrivacyPolicy}
									/>
									<Route
										path='/cookie-policy'
										component={CookiePolicy}
									/>
									<Route
										path='/contact'
										component={Contact}
									/>
									<Route
										path='/saved'
										component={SavedProperties}
									/>
									<Route
										path='/forgotten-password'
										component={ForgottenPassword}
									/>
									<Route
										path='/set-password/:userID/:token'
										component={ResetPassword}
									/>
									<PrivateRoute
										exact
										path='/user-panel'
										component={UserHome}
									/>
									<PrivateRoute
										path='/user-panel/resources'
										component={Resources}
									/>
									<PrivateRoute
										path='/user-panel/contact'
										component={TenantContact}
									/>
									<PrivateRoute
										path='/user-panel/payments'
										component={Payments}
									/>
									<PrivateRoute
										path='/user-panel/pay/:paymentID'
										component={Pay}
									/>
									<PrivateRoute
										path='/user-panel/secure'
										component={Secure}
									></PrivateRoute>
									<AdminRoute
										exact
										path='/admin-panel'
										component={AdminHome}
									/>
									<AdminRoute
										path='/admin-panel/properties'
										component={ManageProperties}
									/>
									<AdminRoute
										path='/admin-panel/property/edit/:propertyId'
										component={EditProperty}
									/>
									<AdminRoute
										path='/admin-panel/users'
										component={ManageUsers}
									/>
									<AdminRoute
										path='/admin-panel/user/:userId'
										component={ManageUser}
									/>
									<AdminRoute
										path='/admin-panel/enquiries'
										component={ManageEnquiries}
									/>
									<AdminRoute
										path='/admin-panel/enquiry/:enquiryId'
										component={ViewEnquiry}
									/>
									<AdminRoute
										path='/admin-panel/tenants'
										component={TenancyPanel}
									/>
									<AdminRoute
										path='/admin-panel/payments'
										component={ManagePayments}
									/>
									<AdminRoute
										path='/admin-panel/payment/:paymentID'
										component={ManagePayment}
									/>
									<AdminRoute
										path='/admin-panel/tenant-enquiries'
										component={ManageTenantEnquiries}
									/>
									<AdminRoute
										path='/admin-panel/tenant-enquiry/:enquiryID'
										component={ViewTenantEnquiry}
									/>
									<AdminRoute
										path='/admin-panel/payments-summary'
										component={PaymentsSummary}
									/>
									<AdminRoute
										path='/admin-panel/settings'
										component={ManageSettings}
									/>
									<AdminRoute
										path='/admin-panel/setting/edit/:settingId'
										component={EditSetting}
									></AdminRoute>
									<Route component={NotFound} />
								</Switch>
								<Footer />
							</div>
						</ScrollToTop>
					</AppContainer>
				</Router>
			</Provider>
		);
	}
}

export default App;
