import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  savePropertyToLocalStorage,
  deleteSavedProperty
} from '../../actions/propertyActions';
import isEmpty from '../../validation/is-empty';

class SaveButton extends Component {
  constructor() {
    super();

    this.state = {
      saved: false
    };

    this.saveProperty = this.saveProperty.bind(this);
    this.deleteProperty = this.deleteProperty.bind(this);
  }

  componentDidMount() {
    if (!isEmpty(this.props.property)) {
      // turn local storage into array
      const storedProperties = JSON.parse(
        localStorage.getItem('savedProperties')
      );

      if (
        !isEmpty(storedProperties) &&
        storedProperties.includes(this.props.property._id.toString())
      ) {
        this.setState({ saved: true });
      } else {
        this.setState({ saved: false });
      }
    }
  }

  saveProperty(e) {
    e.preventDefault();

    this.props.savePropertyToLocalStorage(this.props.property._id);
    this.setState({ saved: true });
  }

  deleteProperty(e) {
    e.preventDefault();

    this.props.deleteSavedProperty(this.props.property._id);
    this.setState({ saved: false });
  }

  render() {
    const { isPropertyDetails = false } = this.props;

    const flexStyle = {display: "flex", justifyContent: "center", alignItems: "center"};

    return (
      <div className="save-wrapper">
        {!this.state.saved ? (
          <button onClick={this.saveProperty} style={isPropertyDetails ? flexStyle : {}}>
            {isPropertyDetails ? <div style={{ marginRight: "10px" }} className="heading">Save Property</div> : null}
            <i className="far fa-heart" />
          </button>
        ) : (
          <button onClick={this.deleteProperty} style={isPropertyDetails ? flexStyle : {}}>
            {isPropertyDetails ? <div style={{ marginRight: "10px" }} className="heading">Delete Saved Property</div> : null}
            <i className="fas fa-heart" />
          </button>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  properties: state.properties
});

export default connect(
  mapStateToProps,
  { savePropertyToLocalStorage, deleteSavedProperty }
)(SaveButton);
