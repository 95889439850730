import React, { Component } from 'react';
import { connect } from 'react-redux';
import { uploadImage, getSetting, updateSetting } from '../../actions/settingActions';
import isEmpty from '../../validation/is-empty';
import { withRouter } from 'react-router-dom';

// import components
// import EditsettingModal from './EditsettingModal';
// import FlatDetails from './FlatDetails';
// import ImageDetails from './ImageDetails';
import Spinner from '../common/Spinner';
import AdminNavigation from '../admin/AdminNavigation';

class EditSetting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      link: '',
      description: '',
      image: '',
      images: [],
      draggedImageIndex: null,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  handleBack() {
    this.props.history.push('/admin-panel/settings');
  }

  componentDidMount(prevProps) {
    // console.log(this.props.match.params.settingId);
    this.props.getSetting(this.props.match.params.settingId);
    
    // console.log(prevProps);
    // if (prevProps.setting.images !== this.props.settings.setting.images) {
      // this.setState({ images: this.props.settings.setting.images });
    // }
  }
  
  handleSelectedFile = (event) => {
    const selectedFile = event.target.files[0];
    const { setting } = this.props.settings;
    // console.log(setting);

    const img = new FormData();
    img.append("image", selectedFile, selectedFile.name);

    // Set the sort_order_id as the next number in sequence
    // const sort_order_id = this.state.images.length
    //   ? Math.max(...this.state.images.map((img) => img.sort_order_id)) + 1
    //   : 1;
    // img.append("sort_order_id", sort_order_id);

    this.props.uploadImage(setting._id, img);
  };
  
  deleteSelectedImage = (mediaKey) => {
    const updatedImages = this.state.images.filter(
      (img) => img.mediaKey !== mediaKey
    );

    const reorderedImages = updatedImages.map((img, index) => ({
      ...img,
      sort_order_id: index + 1,
    }));
    // console.log('reorderedImages ',reorderedImages);
    this.setState({ images: reorderedImages });
  };

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps?.settings);
    this.setState({...nextProps?.settings?.setting})
    
    if(nextProps?.settings.isUpdated) {
      this.props.history.push(`/admin-panel/settings`);
    }
  }

  onChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const { name, link, description, images } = this.state;
    // console.log(this.props.settings.setting);
    this.props.updateSetting(this.props.settings.setting._id, {
      name,
      link,
      description,
      images
    });

    // this.setState({ editStatus: false });
    // this.props.history.push(`/admin-panel/settings`);
  }

  render() {
    // console.log(this.props);
    const { setting, singleLoading } = this.props.settings;
    let settingDetails;
    const { name, link, description, images } = this.state;

    if (isEmpty(setting) || singleLoading) {
      settingDetails = <Spinner />;
    } else {
      settingDetails = (
        <div className="content">
          <div className="admin-section">
            <div className="row">
              <div className="col-md-12">
                <div className="section-content">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="meta-section">
                        <h4>Name</h4>
                        <input
                          name="name"
                          type="text"
                          value={name}
                          size="40"
                          onChange={this.onChange}
                        />
                      </div>
                    </div>

                    {setting?.active ? (
                    <div className="col-md-6">
                      <div className="meta-section">
                        <h4>Link</h4>
                        <input
                          name="link"
                          type="text"
                          value={link}
                          size="40"
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    ) : '' }

                    <div className="col-md-6">
                      <div className="meta-section border-0">
                        {/* <h4>Active?</h4>
                        <p>{setting.active ? 'Yes' : 'No'}</p> */}
                      </div>
                    </div>
                    
                    {setting?.description ? (                      
                    <div className="col-md-12">
                      <div className="meta-section">
                        <h4>Description</h4>
                        <textarea
                          className="w-100"
                          type="text"
                          name="description"
                          rows="12"
                          value={description}
                          onChange={this.onChange}
                          placeholder=""
                        />
                      </div>
                    </div>
                    ) : '' }

                    {/* {setting?.image ? ( */}
                    <div className="col-md-12">
                      <div className="row section-content">
                        <div className="col-md-6">
                          <div><div className="meta-section">
                            <h4 className="heading">Banner Settings</h4>
                            <p>
                              Here are the home page banner image and link settings.{" "}
                              <i>Recommended photo size is 840x285px.</i>
                            </p>
                          </div></div>
                          
                          <div><div className="meta-section">
                            <p className="new-media">
                              <h4 className="subheading">Upload Banner Image</h4>
                              <input
                                type="file"
                                id="image"
                                name="image"
                                accept="image/png, image/jpeg"
                                onChange={this.handleSelectedFile}
                              />
                            </p>
                          </div></div>
                        </div>
                        <div className="col-md-6">
                        <div>
                        <div className="meta-section">
                          {setting.active ? (
                            <>
                              <h4 className='mb-2'>Banner Image</h4>
                              <img
                                src={`/api/media/image/${setting?.image}`}
                                alt="Student Bedroom"
                                width={300}
                              />
                            </>
                          ) : (
                            <>
                              <h4 className='mb-2'>Images</h4>
                              <div className="row image-details">
                                {images
                                  .slice()
                                  .sort((a, b) => a.sort_order_id - b.sort_order_id)
                                  .map((image, i) => (
                                    image.mediaKey && (
                                      <div
                                        className="col-md-6 col-6"
                                        key={image.mediaKey}
                                        draggable
                                        // onDragStart={(e) => this.onDragStart(e, i)}
                                        // onDragOver={this.onDragOver}
                                        // onDrop={(e) => this.onDrop(e, i)}
                                      >
                                        <div className="img-wrapper">
                                          <button
                                            className="delete-icon"
                                            onClick={() => this.deleteSelectedImage(image.mediaKey)}
                                          >
                                            <span className="fa-stack">
                                              <i className="fas fa-circle fa-stack-2x" />
                                              <i className="fas fa-times fa-stack-1x fa-inverse" />
                                            </span>
                                          </button>
                                          <img
                                            src={`/api/media/image/${image?.mediaKey}`}
                                            alt="Gallery"
                                          />
                                        </div>
                                      </div>
                                    )
                                  ))}
                              </div>
                            </>
                          )}
                          </div>
                        </div>

                        </div>
                      </div>
                    </div>
                    {/* ) : '' } */}

                    <div className="col-md-12 footer">
                      <button className="btn green-button" onClick={this.onSubmit}>
                        {singleLoading ? <i className="fas fa-circle-notch fa-spin" /> : 'Submit'}
                      </button>

                      <button className="btn green-button ml-2" onClick={this.handleBack}>
                        Back to Settings
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>

          {/* <ImageDetails setting={setting} /> */}

          {/* <FlatDetails flats={flats} setting={setting} /> */}

          {/* <div className="admin-section remove-footer">
            <h2 className="heading">Delete setting</h2>
            <p>
              <strong className="text-uppercase">Warning:</strong> Settings
              cannot be recovered once they are deleted. When deleting a
              setting, the attached flats and rooms are also permanently
              deleted. Please ensure that you take caution.
            </p>

            <button className="btn red-button" onClick={this.onDelete}>
              Delete setting
            </button>
          </div> */}

        </div>
      );
    }

    return (
      <section className="admin-panel">
        <div className="container">
          <AdminNavigation />
          <div className="panel-content">
            <h1 className="heading">
              Manage setting {' '}
              {setting?._id ? (
                <small>
                  <code>ID: {setting._id}</code>{' '}
                </small>
              ) : null}
            </h1>

            {settingDetails}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  settings: state.settings
});

export default connect(
  mapStateToProps,
  { uploadImage, getSetting, updateSetting }
)(EditSetting);
