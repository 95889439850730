import React, { Component } from "react";

// import components
import EditRoomModal from "./EditRoomModal";
import NewRoomModal from "./NewRoomModal";
import AddFlatModal from "./AddFlatModal";
import EditFlatModal from "./EditFlatModal";
import NewIndividualRoom from "./NewIndividualRoom";
import FlatImages from "./FlatImages";
import FlatVideoEmbed from "./FlatVideoEmbed";
import { connect } from "react-redux";
import { updateProperty } from "../../actions/propertyActions";

class FlatDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flatList: this.props.flats,
      draggedIndex: null,
    };
  }

  onDragStart = (index) => {
    this.setState({ draggedIndex: index });
  };

  onDragOver = (index) => {
    const { draggedIndex, flatList } = this.state;
    if (draggedIndex === index) return; // No need to move the item if it's the same index

    const reorderedFlatList = [...flatList];
    const draggedItem = reorderedFlatList[draggedIndex];

    // Remove the dragged item and insert it before the hovered item
    reorderedFlatList.splice(draggedIndex, 1);
    reorderedFlatList.splice(index, 0, draggedItem);

    this.setState({
      flatList: reorderedFlatList,
      draggedIndex: index,
    });
  };

  onDrop = () => {
    const { flatList } = this.state;
    const { property } = this.props;

    const propertyData = {
      ...property.area,
      name: property?.name,
      slug: property?.slug,
      addressLine1: property?.address?.line1 || "",
      addressCity: property?.address?.city,
      addressCounty: property?.address?.county,
      addressPostcode: property?.address?.postcode,
      flats: flatList,
    };

    this.props.updateProperty(property._id, propertyData);
    this.setState({ draggedIndex: null });
  };

  getAvailableRooms(flat) {
    let availableCount = 0;

    flat.rooms.map((obj) => {
      return obj?.room?.available ? availableCount++ : null;
    });

    return availableCount;
  }

  render() {
    const { flatList } = this.state;
    const { property } = this.props;

    return (
      <div className="admin-section flat-details">
        <div className="section-info">
          <h2 className="heading">Flats & Rooms</h2>
        </div>

        <div className="accordion" id="accordionExample">
          {property.individualRooms.length > 0 && (
            <div className="card">
              <div className="card-header">
                <button
                  className="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target={`#individualRooms`}
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Individual Rooms
                  {" - "}
                  <span className="rooms font-weight-bold">
                    Total Rooms: {property.individualRooms.length}
                  </span>
                </button>
              </div>

              <div
                id={`individualRooms`}
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div className="card-body">
                  <table>
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <th className="text-center">Price</th>
                        <th className="text-center">Type</th>
                        <th className="text-center">Currently Available?</th>
                        <th className="text-center">Future Availability?</th>
                        <th className="text-center">Current Tenant</th>
                        <th className="text-center">Future Tenant</th>
                      </tr>

                      {property.individualRooms.map(({ room }) => (
                        <tr key={room?._id}>
                          <td>{room?.name}</td>
                          <td className="text-center">£{room?.price}</td>
                          <td className="text-center">{room?.type}</td>
                          <td className="text-center">
                            {room?.available ? (
                              <i className="fas fa-check text-success" />
                            ) : (
                              <i className="fas fa-times text-danger" />
                            )}
                          </td>
                          <td className="text-center">
                            {room?.futureAvailability ? (
                              <i className="fas fa-check text-success" />
                            ) : (
                              <i className="fas fa-times text-danger" />
                            )}
                          </td>
                          <td
                            className="text-center"
                            title={
                              room?.tenant ? room?.tenant?.user?.email : "-"
                            }
                          >
                            {room?.tenant ? room?.tenant?.user?.name : "-"}
                          </td>

                          <td
                            className="text-center"
                            title={
                              room?.futureTenant
                                ? room?.futureTenant?.user?.email
                                : "-"
                            }
                          >
                            {room?.futureTenant
                              ? room?.futureTenant?.user?.name
                              : "-"}
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-link p-0"
                              data-toggle="modal"
                              data-target={`#id${room?._id}`}
                            >
                              <span className="badge badge-pill badge-success">
                                Edit
                              </span>
                            </button>

                            <EditRoomModal room={room} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {flatList.map((obj, index) => (
            <div
              className="card"
              key={obj.flat._id}
              draggable
              onDragStart={() => this.onDragStart(index)}
              onDragOver={() => this.onDragOver(index)}
              onDragEnd={this.onDrop}
            >
              <div className="card-header" id="headingOne">
                <button
                  className="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target={`#id${obj.flat._id}`}
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  {obj.flat.name}
                  {" - "}
                  <span className="rooms font-weight-bold">
                    Total Rooms: {obj.flat.rooms.length}
                  </span>
                  {" - "}
                  <span className="rooms-available font-weight-bold">
                    Available Rooms: {this.getAvailableRooms(obj.flat)}
                  </span>
                </button>
              </div>

              <div
                id={`id${obj.flat._id}`}
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div className="card-body">
                  <div className="edit-flat">
                    <button
                      className="btn green-button"
                      data-toggle="modal"
                      data-target={`#editFlat${obj.flat._id}`}
                    >
                      Edit Flat Details
                    </button>

                    <EditFlatModal flat={obj.flat} />

                    <FlatImages flat={obj.flat} />

                    <FlatVideoEmbed
                      flat={obj.flat}
                      initialID={obj.flat.videoID ? obj.flat.videoID : ""}
                    />
                  </div>

                  <h3 className="subheading">Associated Rooms</h3>
                  <table>
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <th className="text-center">Price</th>
                        <th className="text-center">Type</th>
                        <th className="text-center">Currently Available?</th>
                        <th className="text-center">Future Availability?</th>
                        <th className="text-center">Current Tenant</th>
                        <th className="text-center">Future Tenant</th>
                      </tr>

                      {obj.flat.rooms.map((obj) => (
                        <tr key={obj?.room?._id}>
                          <td>{obj?.room?.name}</td>
                          <td className="text-center">£{obj?.room?.price}</td>
                          <td className="text-center">{obj?.room?.type}</td>
                          <td className="text-center">
                            {obj?.room?.available ? (
                              <i className="fas fa-check text-success" />
                            ) : (
                              <i className="fas fa-times text-danger" />
                            )}
                          </td>
                          <td className="text-center">
                            {obj?.room?.futureAvailability ? (
                              <i className="fas fa-check text-success" />
                            ) : (
                              <i className="fas fa-times text-danger" />
                            )}
                          </td>
                          <td
                            className="text-center"
                            title={
                              obj?.room?.tenant
                                ? obj?.room?.tenant?.user?.email
                                : "-"
                            }
                          >
                            {obj?.room?.tenant
                              ? obj?.room?.tenant?.user?.name
                              : "-"}
                          </td>

                          <td
                            className="text-center"
                            title={
                              obj?.room?.futureTenant
                                ? obj?.room?.futureTenant?.user?.email
                                : "-"
                            }
                          >
                            {obj?.room?.futureTenant
                              ? obj?.room?.futureTenant?.user?.name
                              : "-"}
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-link p-0"
                              data-toggle="modal"
                              data-target={`#id${obj?.room?._id}`}
                            >
                              <span className="badge badge-pill badge-success">
                                Edit
                              </span>
                            </button>

                            <EditRoomModal room={obj.room} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <button
                    className="btn green-button"
                    data-toggle="modal"
                    data-target={`#new-room-${obj.flat._id}`}
                  >
                    + Add Room
                  </button>

                  <NewRoomModal
                    flatId={obj.flat._id}
                    propertyId={this.props.property._id}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="add-flat">
          {!property.individualRooms.length > 0 && (
            <React.Fragment>
              <button
                className="btn green-button mr-3"
                data-toggle="modal"
                data-target="#newFlatModal"
              >
                + Add Flat
              </button>
              <AddFlatModal />
            </React.Fragment>
          )}

          {flatList.length === 0 && <NewIndividualRoom />}
        </div>
      </div>
    );
  }
}

export default connect(null, { updateProperty })(FlatDetails);
