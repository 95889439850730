import React, { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "../../validation/is-empty";

// import components
import FlatImages from "./FlatImages";

class FlatList extends Component {
  getAvailableRooms(flat) {
    let availableCount = 0;

    if (flat.rooms && flat.rooms.length > 0) {
      flat.rooms.map(({ room }) => {
        return room?.futureAvailability ? availableCount++ : null;
      });

      return availableCount;
    } else {
      return null;
    }
  }

  getFutureYear() {
    let currentMonth = new Date().getMonth();

    // if month is september, return next year e.g. 2020 otherwise return current year e.g. 2019
    // months start from 0-11, hence month 8 for sept
    return currentMonth >= 8
      ? new Date().getFullYear() + 1
      : new Date().getFullYear();
  }

  render() {
    const { property, loading } = this.props.properties;
    const { flats } = this.props.properties.property;

    if (!loading || !isEmpty(property)) {
    }

    return (
      <div
        className="tab-pane fade show flat-list"
        id="rooms"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        {!isEmpty(flats)
          ? flats.map((obj) => (
              <div className="card" key={obj.flat._id}>
                <div className="card-header" id="headingOne">
                  <button
                    type="button"
                    data-toggle="collapse"
                    data-target={`#id${obj.flat._id}`}
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    {obj.flat.name}{" "}
                    <span className="rooms">
                      Rooms: {obj.flat.rooms.length}
                    </span>
                    {/* {' - '}
                    <span className="rooms-available">
                      Available: {this.getAvailableRooms(obj.flat)}
                    </span> */}
                  </button>
                </div>

                <div
                  id={`id${obj.flat._id}`}
                  className="collapse show"
                  aria-labelledby="headingOne"
                >
                  <div className="card-body">
                    {(!isEmpty(obj.flat.images) ||
                      !isEmpty(obj.flat.videoID)) && (
                      <FlatImages
                        images={obj.flat.images}
                        videoID={obj.flat.videoID}
                      />
                    )}
                    <table className="table table-responsive">
                      <tbody>
                        <tr>
                          <th className="w-100" />
                          <th className="text-center align-middle">Price</th>
                          <th className="text-center align-middle">Type</th>
                          <th className="text-center">Current Availability?</th>
                          <th className="text-center">
                            Sept {this.getFutureYear()} Availability?
                          </th>
                        </tr>

                        {obj.flat.rooms.map((obj) => (
                          <tr key={obj?.room?._id}>
                            <td>{obj?.room?.name}</td>
                            <td className="text-center">£{obj?.room?.price}</td>
                            <td className="text-center no-wrap">
                              {obj?.room?.type}
                            </td>
                            <td className="text-center">
                              {obj?.room?.available ? (
                                <i className="fas fa-check" />
                              ) : (
                                <i className="fas fa-times" />
                              )}
                            </td>
                            <td className="text-center">
                              {obj?.room?.futureAvailability ? (
                                <i className="fas fa-check" />
                              ) : (
                                <i className="fas fa-times" />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ))
          : null}

        {!isEmpty(property.individualRooms) && (
          <div className="card">
            <div className="card-header" id="headingOne">
              <button
                type="button"
                data-toggle="collapse"
                data-target={`#individualRoomsCard`}
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                {property.name}{" "}
                <span className="rooms">
                  Rooms: {property.individualRooms.length}
                </span>
                {/* {' - '}
              <span className="rooms-available">
                Available: {this.getAvailableRooms(flat)}
              </span> */}
              </button>
            </div>

            <div
              id={`individualRoomsCard`}
              className="collapse show"
              aria-labelledby="headingOne"
            >
              <div className="card-body">
                <table className="table table-responsive">
                  <tbody>
                    <tr>
                      <th className="w-100" />
                      <th className="text-center align-middle">Price</th>
                      <th className="text-center align-middle">Type</th>
                      <th className="text-center">Current Availability?</th>
                      <th className="text-center">
                        Sept {this.getFutureYear()} Availability?
                      </th>
                    </tr>

                    {property.individualRooms.map(({ room }) => (
                      <tr key={room?._id}>
                        <td>{room?.name}</td>
                        <td className="text-center">£{room?.price}</td>
                        <td className="text-center">{room?.type}</td>
                        <td className="text-center">
                          {room?.available ? (
                            <i className="fas fa-check" />
                          ) : (
                            <i className="fas fa-times" />
                          )}
                        </td>
                        <td className="text-center">
                          {room?.futureAvailability ? (
                            <i className="fas fa-check" />
                          ) : (
                            <i className="fas fa-times" />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  properties: state.properties,
});

export default connect(mapStateToProps, null)(FlatList);
